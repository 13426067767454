import React from 'react';
import styled from 'styled-components';

const Button = styled.a`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-decoration: none;
  width: 180px;
  height: 60px;
  color: #222
  width: 180px;
  background-size: cover;
  position: relative;
  image-rendering: -webkit-optimize-contrast
  font-family: proxima-nova, sans-serif;
  font-display: swap;

  @media screen and (max-width:767px) {
    width: 160px
  }

  :link, :visited {
    color: #222
  }

  &:hover {
    svg {
      path {
        d: path('M10,10 C10,10 50,7 90,7 C130,7 170,10 170,10 C170,10 172,20 172,30 C172,40 170,50 170,50 C170,50 130,53 90,53 C50,53 10,50 10,50 C10,50 8,40 8,30 C8,20 10,10 10,10 Z');
      }
    }
    span {
      transform: scale(1.15);
    }
  }
`;

const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  stroke-width: 2px;
  fill: none;
  fill-rule: evenodd;
  stroke: currentColor;
`;

const StyledPath = styled.path`
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: all 0.1s ease-out;
`;

const Text = styled.span`
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  transform: scale(1);
  transition: all 0.1s ease-out;
`;

const BookNow = () => (
  <Button href="/book-now">
    <StyledSvg viewBox="0 0 180 60">
      <StyledPath d="M10,10 C10,10 50,9.98999977 90,9.98999977 C130,9.98999977 170,10 170,10 C170,10 170.009995,20 170.009995,30 C170.009995,40 170,50 170,50 C170,50 130,50.0099983 90,50.0099983 C50,50.0099983 10,50 10,50 C10,50 9.98999977,40 9.98999977,30 C9.98999977,20 10,10 10,10 Z" />
    </StyledSvg>
    <Text>Book Now</Text>
  </Button>
);

export { Button };
export default BookNow;
