import React, { useState } from 'react';
import { useMyBunac } from '../../hooks/useMyBunac';

const FORM_ID = 'newsletter';
const PIPELINE_ID = 29;
const PIPEDRIVE_FIELD_KEY_EMAIL = 'email';

const NewsletterForm = () => {
  const { request } = useMyBunac();
  const [email, setEmail] = useState('');
  const [wasSent, setWasSent] = useState(false);

  const handleChange = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const requestParams = {
      path: '/form',
      method: 'POST',
      params: {
        id: FORM_ID,
        pipelineid: PIPELINE_ID,
        fields: JSON.stringify({
          [PIPEDRIVE_FIELD_KEY_EMAIL]: email,
        }),
        notes: JSON.stringify([
          {
            model: 'deal',
            html: `Stay Posted: ${email}`,
          },
        ]),
      },
    };
    try {
      await request(requestParams);
      setWasSent(true);
    } catch (error) {
      console.error(error.message);
    }
  };

  if (wasSent) {
    return (
      <div>
        <p>&nbsp;</p>
        <p>
          Thanks for signing up. Keep an eye on your emails for the latest
          travel inspiration.
        </p>
      </div>
    );
  }

  return (
    <div>
      <h3>Stay posted on everything BUNAC</h3>
      <div id="newsletterSignup" className="emailSignup">
        <form onSubmit={handleSubmit}>
          <input
            name="email"
            onChange={handleChange}
            type="email"
            id="newsletterEmail"
            placeholder="Enter your email"
            required
          />
          <input
            type="submit"
            name="submit"
            value="Sign Up"
            id="newsletterSubmit"
            className="cta"
          />
        </form>
      </div>
    </div>
  );
};

export default NewsletterForm;
