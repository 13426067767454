import React, { useEffect, FunctionComponent } from 'react';

import '../../assets/css/main.css';
import './css/Complete.css';
import '../../assets/css/FontAwesome.css';
import './css/Table.css';

import Header from './Header';
import Footer from './Footer';
import { Footer as Fter } from '../../components/LayoutV2/Footer';
import Skyline from './Skyline';
import { FreshChat } from './3rdParty/freshchat';
import Content from './Content';
import EmbedProximaNovaFont from './font/EmbedProximaNovaFont';
import styled from 'styled-components';
import createPersistedState from 'use-persisted-state';
import { Currencies } from '../config/currency';
import { SEO } from '../seo';

const useCurrency = createPersistedState('currency');

interface Props {
  title: string;
  description?: string;
  noIndex?: boolean;
  children?: React.ReactNode;
}

const Layout: FunctionComponent<Props> = ({
  title,
  description = '',
  noIndex,
  children,
}) => {
  const [activeCurrency, setActiveCurrency] = useCurrency(Currencies.default);

  useEffect(() => {
    setActiveCurrency(activeCurrency);
    // eslint-disable-next-line
  }, []);

  let footer =
    (<div>
      <Skyline />
      <Footer />
    </div>);

  if (title == "Homepage")
  footer = <Fter />

    return (
      <Container className="wrapper layout-v1">
        <SEO
          title={title}
          description={description}
          noIndex={noIndex}
          meta={[
            {
              'facebook-domain-verification': 'hv0ysbd6sfd1xtjxangxx4ccm1p2of',
              charSet: 'utf-8',
            },
          ]}
        />
        <Header />
        <main>
          {children}
          {footer}
        </main>
        <EmbedProximaNovaFont />
        <FreshChat />
      </Container>
    );
};

const Container = styled.div``;

export { Content };

export default Layout;
