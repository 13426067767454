import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const FreshChat = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiFreshChat {
        Token
        Host
        WidgetUUID
      }
    }
  `);
  useEffect(() => {
    const script = document.createElement('script');
    script.onload = () => {
      window.fcWidget.init({
        token: data.strapiFreshChat.Token,
        host: data.strapiFreshChat.Host,
        widgetUuid: data.strapiFreshChat.WidgetUUID,
        config: {
          eagerLoad: true,
          cssNames: {
            widget: 'custom_fc_frame',
          },
        },
      });
    };
    script.src = 'https://wchat.freshchat.com/js/widget.js';
    script.async = true;

    document.head.appendChild(script);
  }, []);

  return <></>;
};
