import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import formatSlug from '../../lib/slugFormatter';

const NAVIGATION_ID = 'footer';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterNavigationQuery {
      allStrapiNavigations {
        edges {
          node {
            Title
            Navigation_Id
            strapiId
            Pages {
              Pages {
                id
                Slug
                Display_Title
              }
            }
          }
        }
      }
    }
  `);

  const linkList = data.allStrapiNavigations.edges
    .map(elem => elem.node)
    .find(elem => elem.Navigation_Id === NAVIGATION_ID)
    .Pages.Pages.map(page => {
      return {
        ...page,
        title: page.Display_Title,
        slug: page.Slug || formatSlug([page.Display_Title]),
      };
    });

  return (
    <ul>
      {linkList.map(link => (
        <li key={link.slug}>
          <Link to={link.slug}>{link.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export default Footer;