import React from 'react';
import styled from 'styled-components';

export default function Skyline() {
  const svgTag = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1499 248" style="enable-background:new 0 0 1499 248;" xml:space="preserve">
            <path class="st0" d="M418,181.7c1.9-3.7,5.9-4.7,8.9-6.9c5.1-3.8,10.5-7.5,16.2-10.4c5.2-2.7,9.8-6.4,15.7-8.1
    c3.2-0.9,6.2-2.7,9.5-3.6c3.4-1,6.7-2,10.2-2c15,0,30,0,45,0c2.2,0,4.5,0.5,6.5-0.1c2.1-0.7,4.4-0.2,6.1-1c1.9-0.9,4.3,0,5.8-1.8
    c5.9-0.8,11.4-3.4,16.4-6.1c7.7-4.2,15-9.1,22.1-14.3c6-4.3,12.2-8.4,18.1-12.8c7.1-5.3,14.8-9.6,22.1-14.6c3-2.1,6.5-3.6,9.9-5
    c3.4-1.4,6.5-3.3,10.2-4.5c4.4-1.4,8.5-3.5,12.9-5c2.5-0.8,5.1-1.2,7.6-2.2c2.4-1,5.3-1.1,7.7-1.9c4.7-1.5,9.7-1,14.3-2.8
    c0.1-0.1,0.3,0,0.5,0c6.4,0.5,12.7-1.2,19.1-1.1c25.7,0.2,51.3,0.1,77,0.1c17.3,2,34.7,0.8,52.1,0.9c6.8,0,13.1-2.4,19-6
    c5.7-3.5,10.9-7.6,16.1-11.9c5.6-4.7,11.2-9.5,17.3-13.6c5.5-3.6,11.3-6.4,17.2-9.1c1.4-0.7,3.3,0.4,4.5-1.2c3.6,0.2,7-1.1,10.6-1.1
    c16,0.2,32,0.2,48,0c6.9-0.1,13.7,1.5,20.6,1.1c6.8-0.4,13.7-0.1,20.5-0.1c7,0,14-0.1,21,0c7.8,0.2,14.5-2.5,20.7-6.8
    c5.7-3.9,11.2-8.2,17.1-12c5.3-3.5,10.8-6.8,17.4-7.5c0.1,0,0.2-0.5,0.4-0.8c17.3,0,34.7,0,52,0c3,1.1,6.2,0.2,9.3,0.5
    c2.9,0,5.9,0,8.8,0c3,0,6,0,9,0c3.4,0,6.7,0,10.1,0c5.9,0,11.7,0,17.6,0c3.4,0,6.7,0,10.1,0c2.1,0,4.3,0,6.4,0c4.3,0,8.6,0,12.8,0
    c4.3,0,8.6,0,12.9,0c4.3,0,8.5,0,12.8,0c2.4,0,4.8,0,7.1,0c0.3,0,0.7,0,1,0c28.1-0.4,56.3,0.6,84.4,0.5c19-0.1,36.9,4.7,54.3,11.7
    c2.4,1,5.1,1.8,6.4,4.5c-0.6,2.3-2.6,2.5-4.3,2.5c-10.9-0.2-18.9,5.5-26.5,12.3c-2,1.8-4.1,3.5-6.2,5c-4.4,3.2-9.3,4.9-14.9,4.9
    c-13.3-0.1-26.7-0.1-40,0c-3.5,0-6.7,0.6-7.4,4.9c1,2.6,3.1,2.9,5.3,3.1c2.3,0.1,4.7,0.1,7,0.1c42.3,0,84.6,0.1,127-0.1
    c6.9,0,11.8,2.3,16.3,7.6c16.6,19.5,27,42.3,35.4,66.1c4.9,13.8,9.8,27.6,11.3,42.3c0,12.3,0,24.7,0,37c-4.4-2.1-8.2-5.1-12.2-7.9
    c-2.1-1.4-4.8-1.8-6.3-4.1c-8.8-4.5-17.5-9.1-26.4-13.3c-6.2-3-12.8-5.5-19.4-7.3c-4.9-1.4-9.9-3.2-15.1-3.3
    c-2.6-1.8-5.7-0.3-8.4-1.4c-3.1-1.2-6.2-0.4-9.2-0.6c-3-0.2-6,0-9,0c-41.4,0-82.9,0-124.3,0c-6.3,0-12.4-1.9-18.2-4.1
    c-7.5-2.8-13.8-7.6-20.6-11.6c-0.1-0.1-0.2,0-0.4-0.1c-0.6,1.2-0.1,2.6-0.3,3.9c-3.5-0.6-2.4-3.4-2.5-5.4
    c-0.1-6.3-0.3-12.5-0.9-19.5c-0.9,4.2-0.8,7.8-0.9,11.4c-0.1,2.1,0.1,4.4-1.9,5.8c-3.3,0.5-5.6-1.5-8.1-3c-1.1-0.7-1.2-2.1-2.1-2.9
    c-0.8-0.1-1.6,0.3-2.3,0.3c-6.9-1.6-13.4-4.7-20.4-6.3c-1.3-0.3-2.3-0.9-3.2-1.8c-2.1-7.3-8.1-11.5-13.1-16.5
    c-0.5-0.4-0.9-0.3-1.3,0.2c-5.3,4.3-9.1,10.1-14.6,14.3c-11,0.9-21.9,0.8-32.9,0.1c-1.6-0.3-2.7-1.7-4.1-2.2
    c-1.6,0.1-2.4,1.6-3.8,1.9c-2.2,0.9-4.7,0.2-7.4,1.8c-1,5.2-0.2,10.8-0.5,16.4c-0.1,2.9,0.6,6.1-1.8,8.5c-3.1-0.3-2.9-2.9-3-4.8
    c-0.1-8.4-0.6-16.9,0.5-25.3c0.9-6.9,1.7-14.3-0.1-20.8c-2.3-8.3-2.3-17.4-7.2-25.1c-2.4-3.8-1.9-8.3-1.9-12.6c0-1.7,0-3.4,0-5.1
    c0-1.6,0-3.2,0-4.9c0.1-1.3-0.3-2.7,0.3-3.9c-0.6,1.2-0.1,2.5-0.3,3.8c0,1.6,0,3.2,0,4.8c0,1.7,0,3.4,0,5.1
    c1.7,8.3-4.3,14.8-5.5,22.4c-1.2,7.7-3.7,15-3.6,23.3c0.2,12.1,1.3,24.2,0.4,36.4c-0.2,2.4,1.1,6-3.1,6.6c-2-2.2-1.6-4.9-1.7-7.5
    c-0.2-5.7,0.3-11.5-0.4-17.7c-9.9-1.6-19.6-0.1-29.3-0.9c-2.9,0.1-3.9-2.1-5-4.1c-1-1.9-1.4-4.1-3.1-6c-2.7,3.2-2.8,8.2-7.5,9.6
    c-6.8,1.1-13.6,0.8-20.4,0.4c-1.4-0.3-3-0.5-4.1-2.7c0.4-10-5.9-18.3-10.4-27.8c-3.7,8.9-9.9,16.5-10,26.6c0,2.2-1.2,3.4-3.3,3.7
    c-4.8,0.9-9.5,1.7-14.4,1.4c-8-0.5-15.1,1.9-21.6,6.3c-2.1,1.4-4,3-6.6,3.5c-2.2,0.1-3.6-1-3.9-3.1c-0.9-5.8-4.9-10.1-6.8-15.5
    c-7.9,10.4-8.9,22.5-10.5,34.3c-1.1,2.1-3.1,3.3-4.1,5.2c-1.1,1.1-2.1,2.2-3.9,1.6c-0.6-0.6-1.1-1.4-1-2.2
    c0.8-5.6-0.8-11.3-0.2-16.8c0.6-5.6,3.4-10.6,6.6-15.2c1.9-2.7,3.4-5.2,2.9-8.3c-2.6-3.6-5-0.4-7.4-0.4
    c-5.9-2.9-11.7-15.3-10.6-22.6c0.4-0.9,0.8-1.9,0.6-2.9c0-0.4,0.2-0.8,0.4-1.2c0.2-0.2,0.4-0.4,0.6-0.6c-0.7,0.1-1.5,0.1-2.2-0.2
    c-1.1-1-2.8-0.7-3.9-1.8c-0.5-1.2-0.7-2.4-0.7-3.6c-0.3,0.8,0.3,1.6,0.1,2.4c-1,3.3-3.7,4.2-6.6,4.2c-3.1,0-4.6-2.4-5.6-5
    c-2.4-6.4-2.9-13-1.9-19.6c0.4-2.4-0.4-4.1-1.4-6.2c-1.1,1.6-1.4,3.2-2.2,4.8c2.1,8.1,3.8,16.4,3.9,24.9c0,0.5,0.3,1.1,0.1,1.5
    c-2.9,7.8,0.7,14.9,2.3,22.2c0.7,3.1,0.8,6.3-0.2,9.4c-0.8,2.6-1.1,5.2,0.4,7.7c0.3,0.4,0.6,1,0.5,1.4c-1.9,7.5,1.8,14.8,0.4,22.2
    c-0.3,1.6-0.4,3.3-1.9,5.1c-4.9,1.1-8.3-2.6-12-5.1c-6.7-4.6-11.2-11.2-15.4-18c-1.8-3-2.2-6.2-2.2-9.6c-0.1-6.3,0.5-12.7-1.7-19.1
    c-5.1-0.2-6.2-3.7-6.1-8.2c-0.2,5.9-1.5,7.4-6.3,7c-1.7,4.7-0.5,9.9-2.3,14.1c-2.2,2.2-4.6,2.2-6.9,2.2c-21.7,0.1-43.3,0.1-65,0
    c-2.2,0-4.4,0-6.5-1.9c-2.2-4.1-0.6-9.2-2.1-13.8c-4.4,0.2-5.4-0.9-6.4-6.9c0-0.4,0.1-0.9,0-1.3c0,0.3,0,0.6,0,0.9
    c0.2,4.3-1.9,7-5.7,8.3c-2.1,3.9-1.8,8.1-1.2,11.9c2.7,18.3-19.7,40.4-36.7,42.5c-6.8,0.8-9.2-0.8-9.1-7.5
    c0.1-7.7-1.6-15.2-1.1-22.9c0.1-2.3-0.2-4.8,1.1-6.8c2.6-4,2.5-7.7-0.3-11.6c-1.8-2.4-1.1-5.4-1.2-8.2c-0.3-7.1,1.1-14.3-0.1-21.4
    c0.8-4.3,0.5-8.6,0.3-11.3c-0.1,2.6,0.6,6.9-0.3,11c0.8,9.1,0.2,18.2-0.2,27.3c-0.1,1.5-1,2.8-1.7,4.1c-1.6,2.8-1.9,5.7-0.3,8.6
    c1.1,2.1,1.5,4.4,1.5,6.8c0.4,18.7-0.9,37.3-3,55.8c-0.9,8.1-1.7,8.6-9.9,9.6c-1.2,0.1-2.3,0.4-3.5,0.5c-13,1.5-21.4-6.9-20-20.2
    c0.4-3.6,1.1-7.2,1.9-10.8c0.4-1.9,1.2-3.9,0.1-6.2c-6.5-1.2-13-0.5-19.5,1.2c-11.2,3.1-10.3,4.7-18-6.6
    c-8.7-12.6-21.2-20.3-36.4-25c1.2,8.1,6.4,14.9,3.8,23.5c-8.2-1-14.9-6.8-23-5.8c-1.1,2,0,3.8,0,5.6c0.1,2.5-0.6,4.4-3.3,4.9
    c-7-0.6-14-2.7-21.4-0.9c2,5.1,5.9,8.8,7.7,13.7c0.7,1.6,1.5,3,2.1,4.7c0.3,1.4,0.9,2.8,0.5,4.4c-1,3-3.6,5.1-4.5,8.3
    c3.1,5.3,2.7,11.6,4.5,17.4c0.2,0.8-0.1,1.6-0.7,2.2c-5.8,2.5-11.8,4.5-17.5,7c-4.2-0.2-7.9,2.6-12.1,2c-4.7,2-9.7,1.2-14.5,0.9
    c-7.2-0.5-14-2.7-20.1-6.7c-4.3-2.8-8.7-5.6-12.7-8.9c-2.1-1.7-4.5-2.5-6.7-3.1c-2.9-0.7-5.9-1.4-9-1.3c-7.1,0.3-13.2-2.6-18.3-7.5
    c-3-2.9-6-5.8-8.8-8.8c-3.2-3.5-6.8-6.5-10.9-8.7C425.8,182.7,422.1,181.3,418,181.7z M1416,97.6c2.1-4.5,1-8.7-1-12.8
    c-2-3.9-5.5-6-9.5-6.1c-19.3-0.2-38.6-0.1-58-0.1c-1.5,0-7.8,2.2-9.2,3.2c-3.6,2.4-7,5.1-10.2,8.1c-3.9,3.6-8.4,6-14.1,5.8
    c-6.3-0.2-12.6-0.1-18.6-0.1c-1.4,1.4-2.4,2.4-3.6,3.6c0.4,1-0.3,2.2,0.6,3.5c0.8,0.5,1.9,1.1,3.2,1.9c34,0,68.3,0,102.6,0
    c4.4,0,8.8,0.7,13.5-1.4c1-1,2.4-2.4,4.1-4.2C1416,98.6,1415.8,98,1416,97.6z M1250.5,47.8c-9.9,0.1-17,6.5-17.3,17
    c-0.3,10.1,6.8,17.5,17.1,17.7c10.6,0.1,16.9-7.5,17.7-17.2C1267.3,55,1260.6,47.8,1250.5,47.8z"></path>
            <path class="st1" d="M1500,205c0,22.3,0,44.7,0,67c-500,0-1000,0-1500,0c0-26,0-52,0-78c1.4-3.3,3.6-5.9,6.1-8.4
    c13.2-13.1,26.7-26.1,39.5-39.6c14.2-15,31.7-21.7,51.9-21.8c34.6-0.2,69.3,0,103.9-0.1c8.1,0,14.8,3,19.6,9.3
    c7.9,10.4,18.3,14,31,13.9c24.2-0.3,48.3,0.1,72.5-0.2c10.7-0.1,18.7,3.9,24.1,13c1,1.7,2.3,3.3,3.3,5c3.2,4.9,7.7,7.1,13.5,7.1
    c17.3,0.1,34.6-0.2,52,0.1c9.3,0.2,16.5,4.7,22.8,11c2.2,2.2,4.6,4.4,6.7,6.7c6.1,6.8,13.5,10.5,22.8,10.5c5.4,0,10.5,1.9,15,5
    c0.8,0.6,1.7,1,2.5,1.7c19.4,16.7,40.6,15.7,62.9,7.1c3.6-1.4,7.1-4.1,11.4-2.1c5.6,6.8,13,8.1,21.5,8.1
    c192.1-0.1,384.1-0.1,576.2,0c7.9,0,13.6-2.2,18.3-8.6c6-8.1,14-12.3,24.5-11.7c9.1,0.6,18.3,0.2,27.5,0.1
    c7.2-0.1,13.4-2.6,18.1-8.2c2.3-2.7,3.8-5.8,2.5-9.7c-3.7-1.8-7.6-0.9-11.3-1c-8-0.2-9-1-10.4-8.9c-0.9-5.5-0.2-11-0.2-16.5
    c0.4-1.6,0.3-3.3,1.9-4.8c3.2-0.8,5.8,1.3,8.4,3c8.1,5.1,16.6,9.1,26.2,10.7c3.3,0.5,6.6,0.6,10,0.6c40,0,80,0.3,119.9-0.1
    c21.6-0.3,42,4.4,61.5,13.2c13.4,6,26.2,13,38.4,21.3C1496.8,200.9,1498.8,202.5,1500,205z"></path>
            <path class="st2" d="M1231,2c0.2,0.2,0.4,0.3,0.6,0.5c-0.2,0.2-0.4,0.3-0.6,0.5"></path>
            <path class="st2" d="M1218,3c-0.2-0.2-0.4-0.3-0.6-0.5c0.2-0.2,0.4-0.3,0.6-0.5"></path>
            <path class="st3" d="M1205,3c-0.2-0.2-0.4-0.3-0.6-0.5c0.2-0.2,0.4-0.3,0.6-0.5"></path>
            <path class="st3" d="M1218,2c0,0.3,0,0.7,0,1"></path>
            <path class="st4" d="M1251,2c0,0.3,0,0.7,0,1"></path>
            <path class="st4" d="M1244,3c0-0.3,0-0.7,0-1"></path>
            <path class="st5" d="M921,163c0.2-1.3-0.3-2.6,0.2-3.8c1.1-0.6,1.6,0,2.3,0.8c0.5-0.6,1.1-1.1,1.7-1.7c-0.9-1.7-1.9-3.2-0.9-5.2
    c0.3-0.1,0.7-0.1,1.3-0.3c0.9-1-1.3-1.2-0.7-2.3c0.4-0.8,0.1-2,0.1-2.9c0.3-1.1,1.6-0.3,2.1-1.4c-0.5-1.4-1.8-2.9-0.7-5
    c0.1,0,0.7-0.2,1.4-0.5c-0.6-1.4-1.1-2.5-0.1-4.1c1.7-2.7,2.5-5.9,4.6-8.6c1.1-1.5,1.4-3.7,1.7-5.7c0.2-1.4,0.9-2,2.1-2.4
    c1.1,0.4,2.3,1,1.9,2.4c-0.4,1.6,1.1,2.7,1,4.1c-0.1,1.7,1.5,2,2,3.2c1.4,2.7,2.7,5.5,4,8.3c0.3,1.1-1.6,1.3-1,2.6
    c0.2,1.1,2.2-0.1,2,1.5c2.8,4.5,2.1,9.9,3.6,14.8c0.8,1.1,1.9,1.1,3.4,0.9c2-8,4.6-15.8,10.5-22.8c6.5,6,8.8,13.3,11,21.2
    c0.6-5.1,4.6-5.5,8-6.5c3.8-4.6,4.9-10,6-15.5c0.3-1.5,0.2-3,1.5-4.1c0.6-0.1,1.3,0.3,2.1-0.6c-1.3-2.1-1.3-4.6-0.9-7.1
    c0.7-0.5,0.7,0.5,1.4,0.4c1-1.1,0.4-2.2-0.3-3c0.5-3.1,2.7-5.4,2.9-8.4c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.3,0.9-0.4
    c0-0.6,0-1.3,0-1.8c0.3-0.9,1.1-0.6,1.7-0.8c1.4-3.6,1.9-7.7,4.2-12.3c2.2,4.8,3.1,8.7,4.5,12.3c0.5,0.1,0.9,0.2,1.5,0.4
    c0,0.7,0.1,1.5,0.2,2.3c0.3,0.5,0.8,0.4,1.6,0.4c0.2,3.1,2.4,5.5,3,8.7c-0.8,0.8-1.1,1.9-0.3,3.3c0.4-0.3,0.7-0.6,1-0.8
    c1,0.4,0.9,3.5-0.3,7.5c0.5,0.7,1.2,0.2,1.9,0.3c1.4,1.2,1.3,2.9,1.5,4.6c1.3,8.1,4.5,15.3,9.8,21.6c0.5,0.6,1.1,1.2,2.4,1.4
    c1.4-1.8,1.5-4.1,1.8-6.3c0.7-5.3,1.7-10.4,3.1-15.5c0.7-2.5,1.7-4.6,4.3-5.7c0-0.5,0-1,0-1.5c0.4-0.7,1.2-0.4,2-0.9
    c-0.8-2.5,2.2-4.1,2-6.8c0-0.6-0.2-1.3,0.5-1.8c0.5-0.4,0.8-1.1,1.6-1c0.8,0,1.4,0.8,1.4,1.1c-0.1,2.7,3.3,4.1,2.5,7
    c1.5,1.3,1.5,3.6,3,4.9c4.4,2.6,2.9,7.9,5.6,11.4c2.1-2.5,2.4-6.8,7-8.3c5.7,5.1,7.4,12.3,9.8,19.3c2.3,6.9-1.4,14.6,3.2,21.3
    c0.1-4.8,3.4-8.4,4-13c0.2-1.9,0.6-4.1,3.5-3.8c1,0.1,1-0.6,1-1.3c0-8.5,0.3-17-0.2-25.4c-0.2-3.2-1.6-6.6-0.6-9.5
    c1.1-3.3,1.2-6.7,0.5-9.6c-0.6-2.9-0.4-5.8-0.8-8.5c0.8-1,1.6-1.8,2.9-1.9c0-3.4,0-6.9,0-10.4c0.7-0.2,1.3-0.4,2-0.7
    c0-2,0-4.2,0-6.2c1-0.6,1.2-2,2.7-1.4c1.5-3.2,2-6.7,2.4-10.1c0.2-1.4,0-2.9,1-4.1c0.2-0.2,0.3-0.4,0.5-0.6c0.2,0.2,0.3,0.4,0.5,0.6
    c0.5,1.1,1.1,2.2,1.1,3.6c0,3.5,1,6.8,2.1,10.1c1.1,0.4,2.2,0.7,3,2.1c0,1.8,0,3.9,0,6c0.6,0.2,1.1,0.3,1.7,0.4c0,3.4,0,6.8,0,10.1
    c1,0.9,2.3,0.9,3.2,2.4c0,7.5,0,15.5,0,23.5c-1.8,4.8-0.8,9.7-1,14.6c-0.1,4.8,0,9.7,0,14.5c0,0.7-0.4,1.8,1,1.8
    c1.7-0.5,2.8,0.6,3,1.9c0.6,4.8,4.1,8.7,3.9,13.8c1.7-12,1.3-24.1,2.2-36.2c0.2-2.6-0.3-5.4,1.9-7.5c-0.4-2,1.4-2.6,2.4-3.9
    c1.7,0.7,1.6,2.7,2.6,3.9c2.2,5.2,1.4,10.8,1.8,16.2c0.4,5.6,0.3,11.3,0.2,17c0,1.7-0.6,3.7,1.3,5c2.2-0.5,1.6-2.3,1.7-3.6
    c0.7-6.6,1.1-13.3,1.1-19.9c0-2.6-0.1-5.4,3.1-6.9c3.6,0.6,4.2,3.5,3.9,6.4c-0.6,6.8,1.2,13.6,0.8,20.4c-0.1,1.4-0.3,3.1,1.5,4
    c2.8,0.2,2.5-2,2.6-3.7c0.2-4.7,0-9.3,0.1-14c0-2.3-0.1-4.7,1.3-6.8c1.3-1.3,2.8-1.1,4.4-0.6c2.2,0.9,2.5-1,3-2.2
    c1.6-3.2,3.4-6.1,7.2-6.4c0.9-1.9,0.5-3.8,2-4.9c1.8-5,5.4-8.3,9.7-11.2c1.2-0.8,1.8-2.3,3.3-2.8c0.7-0.6,1.3-0.6,2,0
    c2,0.8,2.8,3.1,5,3.5c0.5,2.3,2.9,2.5,4.2,4.1c2.3,2.8,4.1,5.6,3.8,9.4c0.9,1.6,2.3,2.3,4.1,2.4c3.5,1.3,4.3,4.8,6.3,7.6
    c1.9-0.2,2.1-2.6,4.2-3c2.7,1.3,2.7,4.3,3,6.9c0.4,5.3,0.1,10.6,0.2,15.9c0,1.9-0.4,4,1.2,5.8c3.4,0.6,3.1-2,3.2-4
    c0.2-6-0.2-11.9,0.4-17.9c0.2-2,0-4,1.4-5.7c0.5-1,1-2.1,1.5-3c2.5,0.1,0.8,2.8,2.8,2.8c-0.5,0.9-0.8,1.4-1.1,1.9
    c0.3,0.1,0.6,0.2,0.9,0.4c2.7,3.9,1.7,8.3,1.9,12.5c0.2,4.2-0.2,8.5,1.7,13.1c2.5-2.3,1.9-5,2.2-7.5c0.6-4.7-0.5-9.6,1.2-14.2
    c1,0.1,1-0.6,1-1.3c0-3.5,0.1-7,0-10.5c0-1.2,0.6-2.1,1-3.1c0-1.3-2-2-1-3.6c0.8-0.8,1.7-1.6,2.5-2.4c1.7,0.6,1.2,2.8,2.4,3.4
    c0.2,1.2-0.5,1.5-1.1,2.3c1.3,1.3,1.2,3,1.2,4.8c-0.1,2.7,0.1,5.3-0.1,8c0,1,0.6,1.4,1.1,2.2c-1.4,2.6-1.5,5.4-0.6,8.3
    c0,0.1,0.3,0,0.5,0c0.2,1.5-0.6,3.3,0.9,4.6c-1.6,1.3-0.8,3-0.9,4.6c-0.1,1.7,0.2,3.4-0.1,5c-0.3,1.8,1.2,2.9,1.1,4.6
    c-0.1,1.6,0,3.3,0,5.1c7.3,0,14.4,0,21.9,0c-0.3,3,1.1,5.9-0.9,8.6c0,4.3-3.4,6.5-6.1,8.9c-2.6,2.5-6,3.7-9.5,4.7
    c-2.9,0.9-5.7,0.9-8.6,0.9c-11.7,0-23.3,0-35,0c-0.2,0-0.3,0.1-0.4,0.2c-5.3,1.7-9,5.5-12.6,9.4c-1.3,1.4-2.3,3.1-3.6,4.5
    c-2,2.1-3.9,4.3-6.6,5.9c-2,0-4,0-5.9,0c-195.6,0-391.2,0-586.8,0c-5.5,0-10.3-1.7-14.1-5.7c-1.2-1.2-1.9-2.9-2.9-4.3
    c-0.1-2.4-2-4.3-1.9-6.6c0.1-1.9-1.6-3.1-1.2-5.1c0.3-1.6,0.1-3.3,0.1-4.8c-0.5-0.5-1-1-1.5-1.5c-0.7,0.6-1.3,1.5-2.8,0.5
    c2.1-3.8,4-7.6,7.2-10.5c0.5-0.5,0.9-0.9,1.4-1.3c2.2-3.2-2.1-3.6-2.4-5.8c-0.5-0.8-1.3-0.6-2-0.9c-2.6-5.9-6.8-10.7-10-16.1
    c0.3-1.4,1.3-0.9,2-0.9c4.3,0,8.6-0.2,12.9,0c3.1,0.2,6.2,0.6,9.1,1.9c1.3,1,2.7,0.4,4,0c0.5-0.4,0.4-0.9,0.2-1.4
    c-0.3-0.9-0.6-1.8-1.2-2.6c-0.3-0.1-0.8-0.3-0.8-0.3c0.3-2.6-1.9-4.8-0.8-7.7c9,0.8,17.6,3.1,25.7,8.4c0-9.6-4.4-17.5-7.2-26.4
    c21,5.9,37.6,16.7,47.4,36.2c16.4-5.6,18.1-5.6,33.9-4.8c-1.2,4.1-2.1,8.2-2.8,12.2c-0.8,4.2-2.2,8.6-1,13.2c0.4,0.4,1,1,1.7,1.7
    c0,1.6,0,3.4,0,5.1c1.2,0.8,2.4,0.3,3.7,0.5c0.7,1.4,0.1,3.1,0.4,5c4.2,0,8.5,0.7,12.4-0.2c3.4-0.7,6.7-0.5,10.1-0.9
    c1.1-3.4,0.7-7.1,1.7-10.4c1-3.5,0-6.9,0.8-10.1c1.4-5.5,0.1-11.2,1.1-16.5c1-5.3,0.6-10.4,0.8-15.6c0.2-5.3,0-10.6,0-15.7
    c-0.8-1.3-1.9-1.5-3-2c0.3-0.4,0.6-0.7,0.9-1.1c-1.2-3.6-1.2-3.6,1.5-9.5c0.5-0.1,1,0.3,1.5-0.7c0.3-2.6-0.6-5.8,1-8.7
    c0.1-0.1,0-0.3,0-0.5c-0.5-3.1-1.8-6-0.4-9.3c1.2-3.1,0.3-6.5,0.4-9.8c0.3-0.5,0.7-0.5,1,0c1.5,1.8,0.9,4,1,6.1c0.2,1.9-0.7,4,1,5.6
    c-2.1,5.1-0.4,10.4-1.1,15.8c2.3,1.8,3.7,4.2,4.7,7c-1.2,1.4-0.3,3.4-0.7,5.1c-0.7,1.2-1.9,1.3-2.9,1.9c0,5.5-0.8,11.1,0.2,16.4
    c1.1,6.3,0.3,12.4,0.9,18.8c5.1,1.3,10-0.3,14.6-2.1c5.9-2.3,11.5-5.5,15.6-10.5c4.1-5,8.2-10,10.7-16c0.6-1.4,1-2.6,0.9-4.1
    c0-3.7,0-7.3,0-11c0-2.3-0.7-5,0.2-6.9c0.9-2,0.3-4.1,1.1-6c0.5,0,0.8,0,1.2,0c0.7,0.3,0.4,1.1,0.7,1.7c1.3,0.6,2.5,0.4,3.8-1.3
    c0.4-0.7-0.2-2,0-3.3c0.1-1.1,1.5-1.8,1-3.1c1.2-0.6,1.4,0.4,2,1c-0.2,2,1.9,3.5,0.9,5.2c1,1.1,1.8,1.9,2.6,2.8c1.3-1,2.1-2.2,1.7-4
    c3.6,5.6,2.8,11.6,2.9,17.8c25.6,0,51,0,76.8,0c0.5-1.8,0.1-4,0.2-6.1c0.1-1.9,0.4-3.8,0.8-5.6c0.4-2,1.9-3.8,0.9-6.6
    c1.8,1.1,1.1,2.2,1.3,3c1.1,0.7,2.4,0.4,3.8,0.3c0.2-3.8,1.7-7.2,2.8-10.9c0,2.6,1.6,4.8,1.4,7.5c-0.1,1.8,1.2,3.2,2.2,4.5
    c1.9-0.7,1.5-3.2,3.3-3.9c1.2,1.5-0.4,3.6,1,5.1c1.1,1.2-0.3,3.2,1.3,4.5c0.5,0.4,0.1,1.9,0.1,2.9c0,5.7,0,11.3,0,16.8
    c10.3,17.7,18.3,23.1,27.8,26.5c0.2-1.6,0.8-3.2-0.7-4.4c0.6-1.1,1.2-2.2,1.9-3.3c0.2-1.2-1.3-1.7-1.1-3c0.5-3.4-0.1-6.7-2.2-9.6
    c1-2.1,2-4.1,3-6.2c-0.5-1.7-3-1.3-3.7-2.8c-0.7-1.4,1.1-1.6,0.9-2.7c-0.3-2,0.6-3.7,0.9-5.6c0.2-1.1,1.6-1.6,1-3
    c-0.5-1.1-1-3.4-1.8-4.8c0.3-0.4,0.6-0.7,0.9-1c0.5-1.1-0.8-1.4-0.8-2.2c0.2-0.2,0.5-0.5,0.8-0.8c-1.1-1.1-1.8-2.3-2.2-4
    c-0.3-1.2-1.6-2.6-2.5-3.9c0.9-2.5,1.2-5.3,2.7-7.7c0.4-0.6,0.4-2,0-2.4c-1.6-1.6-0.9-3.5-0.9-5.2c0.1-3.4,0.1-6.9-1.2-10
    c-1-2.6-0.7-5.1-1-8.1c-0.9-0.7-2-1.6-3-2.4c1.3-1.8,1.6-4.1,4.1-4.1c-1.3-1.2-0.9-2.7-1-4.1c2.5-2.1,2.5-2.1,5,0
    c0.7,1.5-0.8,2.4-1,3.6c0.2,0.3,0.3,0.7,0.5,0.8c2.1,0.2,2.6,1.6,2.4,3.9c-0.9,0.7-2,1.6-3.3,2.6c0.9,1.8,1.7,3.3,2.6,5
    c-2.7,3.3-0.4,6.5,0.5,9.7c0.5,1.8,1,3.6,1.6,5.5c0.8,0.4,1.8,0.9,2.8,1.3c0.3,0.1,0.7,0,1.5,0c1-1.2,2.4-2.5,4.5-2.1
    c0-0.9,0-1.4,0-1.9c2-0.6,1.1,1.5,2,2c1.8,0.6,2.4-1.3,3.6-1.9c1.6,1.3-0.8,1.8-0.6,2.9c0.5,0.9,2.6,0,2,2c0,0.3,0,0.7,0,1
    c0.6,0.7,0.6,1.3,0,2c0.2,2.2-0.6,4.5,0.8,6.7c-0.9,0.5-0.8,1.5-0.7,3.5c0,0,0.8,0.3,1.5,0.9c3.5,2.9,4.3,3.9,5.5,7.6
    c0.9,2.6,0.9,2.6,2.3,4.1c1-0.1,1.3-1.2,2.1-1.6c0.9-0.5,0.4-1.6,1-2.1c3.4-0.8,4.8,2.8,7.7,3.1c0,1.9-1.5,2.9-1.8,4.6
    c0.1,0.1,0.4,0.5,0.7,0.8c-0.9,3.1-2.9,5.2-5.9,7c0,1.3,0,3.1,0,4.8c-1.6,1.2-1.1,4.4-4.2,3.5c0.6,3.9-0.3,7.4,0.9,11
    c0.7,2.1,1,5-0.7,7.6c-0.7,1.1-0.1,2.9-0.1,4.4C920.2,166.2,920,163.6,921,163z"></path>
            <path class="st1" d="M1144,144c0,8.8,0,17.6,0,26.9c-2.5,0.3-5.1,0.1-8,0.2c0-1.8,0-3.4,0-5.1c0-3-0.2-6,0.1-9
    c0.3-3-1.5-5.6-1.1-8.6c0.2-1.6,0-3.2,1.3-4.4c-0.5-0.7-0.8-1.2-1.2-1.7c-0.2-0.8,0.4-1.2,1.1-1.8c-0.9-0.7-1.8-1.4-2.8-2.2
    c-0.1,0.2-0.3,0.9-0.5,1.5c-0.2-0.2-0.5-0.3-0.7-0.5c0,2.3,0.2,4.6,0,6.9c-0.2,2.7,0.9,5.6-0.8,8.2c2.4,2.8-0.7,5.3-0.3,8.1
    c0.4,2.7,0.1,5.6,0.1,8.5c-2,0-3.8,0-5.6,0c-0.4-0.4-0.7-0.7-1.4-1.4c0.5-3-0.6-6.3,0.6-9.7c0.3-0.8-0.6-2.2-0.6-3.4
    c0.1-3.7,0.1-7.3,0-11c-0.1-2,0.3-4-0.8-6.2c-0.8-1.5-0.6-3.8,1-5.2c-0.6-0.8-0.9-1.3-1.1-1.5c-0.4-1.4,1.1-1.5,0.9-2.5
    c12,0,24,0,36,0c0.2,2.5-0.8,5.2,0.9,7.8c-1.3-0.6-2.5-1.2-4-1.9c-1,1.3-2,2.8-3,4.2c-1.2,1.4-1.2,1.4-3,3.5c0,1.3,0,2.7,0,4.1
    c-1,0.3-1.6,0.5-1.9-0.7c-0.2-0.7-0.6-1.4-1-2.1c-0.5-0.5-0.7-1.4-1.5-1.7C1145.5,143,1144.7,143.4,1144,144z"></path>
            <path class="st6" d="M990,130c0.4,2-1.1,4.3,1.2,5.8c-0.7,1.8-3.1,1.1-3.1,3.2c0.6,1.1,0.7,2.8,1.9,3.5c0,1.6-1.3,1.7-1.4,2.4
    c-1.1,0.2-1.5-0.4-2.2-1c-0.8,1.6-0.1,3.2-0.4,4.4c-0.8,1.4-1.5,2.4-2,3.4c-1.3,0.5-2.4,0.6-3.3-0.5c-1.5,0.7-2.9,1.4-4.6,2.2
    c0,2,0,4.1,0,6.1c-1.9,1.4-2.5-1.3-4-0.9c1.3-1.2,0.9-2.7,1-4c-0.3-0.8-1.1-0.5-1.8-0.8c1-1.5,1.5-3.1-0.3-4.4
    c-1.2-0.8-0.7-2-0.8-2.6c-0.6-1.3-1.6-0.4-1.9-1.4c0.2-0.2,0.5-0.6,0.8-0.9c-1.8-2.7-2.9-5.9-5.3-8.5c-2.4,3.7-3.4,8.2-6.5,11.5
    c0.3,0.3,0.6,0.6,0.8,0.9c-1.6,3.6-4.4,6.8-1.7,11.2c-2.3,0.7-4.4,0.7-6.1-0.7c-1,0.1-1.3,1.4-2.5,1c-0.4-0.1-0.5-0.5-0.6-1
    c-0.5-2,1.7-3.5,0.9-5.6c-0.5-0.2-1.1-0.4-2-0.7c0.5-1.7,1.9-3.4,0.9-5.2c-0.5-0.6-1.4,0.1-1.8-1.2c0.3-1.1,1.5-2.4,1-4.1
    c4.6-3,8.9-6.3,14-8.5c3.2-1.4,6.4-2.6,10-2.5c0.7,0,1.3-0.1,2,0C978,131.6,984,130.2,990,130z"></path>
            <path class="st1" d="M1085,158c-1,0-1.2,1-2.1,1.5c0,2.7,0,5.5,0,8.3c-1,0.1-1.7,0.2-2.9,0.3c0,3.5,0,7.1,0,10.5
    c-0.8,0.4-1.3,0.5-1.8,0.1c-0.1-0.8-0.3-1.7-0.4-2.5c-1.2-0.6-1.7,0.1-2.4,0.8c-0.4-0.5-0.8-1-1.4-1.9c0.7-2.1-0.6-4.3-1.1-6.6
    c-0.4-2.1,2.8-4.1,0-6.2c0.4-1.6,2.1-3.1,0.9-5c-0.1-0.5-0.7-0.3-1.2-0.4c-1.3-1,1.1-1.2,0.5-2.3c-0.5-0.9,0.1-2.1-0.9-3.2
    c-0.5-0.5-0.1-1.9-0.1-2.6c-0.4-1-1.1-0.8-1.6-0.9c-0.8-2.4-1.5-4.8-2.5-7c-1-2.1-2.4-4-3.7-6.2c-2.2,1.8-2.9,4.3-4.1,6.6
    c-1.1,2.2-2.3,4.4-3.6,7c-0.6-0.9-1-1.6-1.5-2.5c0.6-1.1,1.5-2.7,0.8-4.6c-0.6-0.2-1.2-0.4-1.9-0.7c1-3.5-1.2-6.4-1.9-9.6
    c-0.1-1,0.6-1,1.3-1c10.5,0,20.9,0,31.7,0C1085,139.4,1085,148.7,1085,158z"></path>
            <path class="st7" d="M1039,130c-2.5,3.1-3.5,6.7-2.9,10.8c-0.7,0.1-1.1,0.2-1.7,0.3c-0.8,1.7-0.6,3.3,0.6,4.8c0,1.4-1.6,1.7-1.9,2.8
    c0,0.9-0.2,1.9,0.1,2.8c0.2,0.7,1.3,1.1,0.6,2.2c-0.4,0.2-1,0.4-1.6,0.6c-0.7,1.8,0.1,3.4,1.1,5.1c-2,1.2-4,0-5.7,0.7
    c-1.5-2.4-1.3-6-5.6-5.1c0.3-2.5-0.4-4.3-1.5-6.1c-0.7-1.2-0.6-1.2,0.5-3.5c-0.4-0.4-0.9-0.9-1.3-1.4c-0.5,0.3-1,0.6-1.5,0.9
    c-1.1,0.1-1.3-0.9-2-1.3c1.5-2,0.6-4.3,0.9-6.3c-0.9-0.3-1.4-0.5-2.4-0.8c1.8-2,1.3-4.3,1.4-6.5C1023.7,130,1031.3,130,1039,130z"></path>
            <path class="st8" d="M1119,130c0,3,0.1,6,0,9c-0.2,2.7,1.1,5.6-1.2,8c0.5,0.6,0.8,1.2,1.2,1.7c-1.8,3.6-0.8,7.4-1,11.1
    c-0.1,3.6,0,7.3,0,10.8c-0.7,0.7-1.3,1.3-1.9,1.9c1.8,1.8,0.5,4.1,1,6c-1.2,1.3-2.6,0.1-3.9,0.9c0-3.9,0-7.3,0-10.5
    c-0.9-1.3-2.1-0.3-3.1-1.1c0-2.6,0-5.4,0-8.1c-0.9-0.5-1.1-1.5-2.1-1.5c0-9.2,0-18.5,0-28C1111.8,130,1115.4,130,1119,130z"></path>
            <path class="st7" d="M1212,141c-0.2,0.6,0.4,0.8,0.8,1.4c0.3,0.6-0.9,0.7-0.8,1.2c-0.4,1.1,0.8,1.3,1,2.4c-0.9,1.1-1.1,2.6-1.1,4.3
    c0.1,6.8,0,13.6,0,20.5c-2.7,0-5.1,0-8,0c0-3.5,0-7.1,0-10.8c0-2.3,0-4.7,0-7s-0.1-4.7,0-7c0.1-2.1-2.2-3.7-1.1-6.1
    c-1.2,1.1-0.7,2.4-0.9,3.5c-2,0.8-2.7,2.7-3.2,4.5c-0.6,0-0.9,0-1.3,0c-0.8-1.3-0.2-2.9-0.5-4.7c-0.6-0.4-1.4-0.9-1.9-1.2
    c0-1.3,0-2,0-2.7c-1.4-1-2.3-2.8-4-3.5c-0.6-0.7-0.6-1.3,0-2c1.7-0.5,3,0.7,4.6,1.1c4.9,1.2,9.8,2.7,14.4,4.9
    C1210.6,140.3,1211.3,140.7,1212,141z"></path>
            <path class="st9" d="M1223,147c1.8,3.5,1,7.2,0.6,10.9c0,0.3-0.4,0.5-0.8,0.9c3.5,3.9-2,7.8,0.7,12.1c-2.5,0-4.3,0-6.3,0
    c-1-1.6,0.7-4.1-1.3-5.6c-0.2-0.8,0.5-1.2,0.9-1.7c-1.1-3-1.8-6-0.1-9.2c-0.1-0.1-0.5-0.3-0.6-0.5c-0.7-3.6-0.1-7.2-0.3-10.8
    C1218.3,144.4,1221.1,144.9,1223,147z"></path>
            <path class="st10" d="M704,89c-0.3,0-0.7,0-1,0c0-4.9,0-9.8,0-14.8C705.2,79.1,705.9,84,704,89z"></path>
            <path class="st11" d="M1191,134c0,0.7,0,1.3,0,2c-1.4,0-1.6,2.1-3.2,2c-0.2,0-0.3-0.1-0.8-0.2c-0.4-1.7,1.6-3.1,0.9-4.8
    C1189.3,132.5,1189.9,133.9,1191,134z"></path>
            <path class="st12" d="M921,163c-0.9,2.2-1.2,4.5-1,6.9c-0.8,0-1.3,0-2,0c0-1.7,0-3.3,0-4.9C919,164.3,920,163.7,921,163z"></path>
            <path class="st13" d="M574,157c1.1,0.2,1.8,0.9,2,2c0.5,1.3,1.6,2.7,0,4c-1.1-0.8-2.4-1.2-3.8-1c-0.7,0.1-1.2-0.1-1.2-1
    c0.9,0,1.8,0,3,0C574,159.5,574,158.2,574,157z"></path>
            <path class="st14" d="M909,96c0.1-0.6-0.3-0.9-0.8-1c-0.7-0.1-1.3-0.1-1.2-1c1.9,0,3.9,0,5.8,0C911.6,95,910.6,96.3,909,96z"></path>
            <path class="st15" d="M1175,116c-0.7,0-1.3,0-2,0c0.2-1.3-0.3-2.6,0.3-3.9c0.5,0,0.9,0,1.4,0C1175.3,113.4,1174.8,114.7,1175,116z"></path>
            <path class="st16" d="M904,93c-0.6,0.1-0.9-0.3-1-0.8c-0.1-0.7-0.1-1.3-1-1.2c0-1.3,0-2.5,0-3.8C902.9,89.1,904.4,90.8,904,93z"></path>
            <path class="st17" d="M557,176c0.8,0.1,0.9-0.7,1.5-0.9c0.4,0.1,0.6,0.4,0.5,0.9c0.9,1.1,2.1,2.2,1.3,3.9c-0.4,0.4-0.8,0.4-1.3,0.1
    C558.3,178.7,557.7,177.3,557,176z"></path>
            <path class="st18" d="M559,180c0.3,0,0.7,0,1,0c2.7,0.3,2.6,2,2,4c-0.7,0-1.3,0-2,0c-0.1-0.6,0.3-1.4-0.3-1.8
    C558.8,181.6,559,180.8,559,180z"></path>
            <path class="st19" d="M909,99c0-0.7,0-1.3,0-2c0.9,0.1,1.9-0.3,2.9,0.4c0,0.4,0,0.8,0,1.3C910.9,99.3,909.9,98.9,909,99z"></path>
            <path class="st20" d="M759,104c-0.7-0.3-1.3-0.7-2-1c0.1-0.9-0.3-1.9,0.3-2.8C759.3,100.8,759,102.5,759,104z"></path>
            <path class="st21" d="M1097,69c-0.3,0-0.7,0-1,0c0-1.7,0-3.3,0-5c0.2-0.2,0.3-0.4,0.5-0.6c0.2,0.2,0.3,0.4,0.5,0.6
    C1097,65.7,1097,67.3,1097,69z"></path>
            <path class="st22" d="M1096,59c0-1.6,0-3.2,0-4.8c1.3,1.5,0.8,3.2,1,4.8C1096.7,59.6,1096.3,59.6,1096,59z"></path>
            <path class="st23" d="M1096,59c0.3,0,0.7,0,1,0c0,1.7,0,3.3,0,5c-0.3,0-0.7,0-1,0C1096,62.3,1096,60.7,1096,59z"></path>
            <path class="st0" d="M843.7,135.2c2.2,12.2,0.9,24.3,1.3,36.3c-0.6,0.6-0.9,0.9-1.4,1.4c-25.3,0-50.7,0-76.6,0
    c-0.1-6.1,0-12.3-0.1-18.6c0-6.3,0-12.6,0-19.1C792.7,135.2,818.2,135.2,843.7,135.2z"></path>
            <path class="st24" d="M839,188.9c-22.1,0-43.9,0-65.6,0c-0.7-1.5-0.4-2.6,0.8-3.5c7.1-1.6,14.3-3,21.3-5.2c3.1-1,6.4-1.4,9.6-2
    c2-0.4,4.5,0.4,6.5,1.1c2.9,1,6,1.9,8.9,2.4c4.8,0.9,9.4,2.2,14.2,3.2c1.6,0.3,2.9,1,4.4,1.6C839,187.2,839,187.8,839,188.9z"></path>
            <path class="st25" d="M1095.2,105.1c1.3,0,2.4,0,3.7,0c0,19.8,0,39.6,0,59.6c-0.9,1.1-2.3-0.1-3.7,0.6
    C1095.2,145.1,1095.2,125.3,1095.2,105.1z"></path>
            <path class="st26" d="M862.1,174.8c0-10.6,0-21,0-32.4c2.5,3.4,4.6,6.2,6.8,9.2c0,7.5,0,15.3,0,23.2
    C866.7,174.8,864.6,174.8,862.1,174.8z"></path>
            <path class="st27" d="M748.9,142.4c0,11.2,0,21.6,0,32.3c-2.1,0-4.3,0-6.7,0c0-7.8,0-15.5,0-23.4
    C744.9,149.3,746.1,145.8,748.9,142.4z"></path>
            <path class="st28" d="M891,179.2c-0.8,1.1-1.2,2.1-1,3.4c-0.8,0.7-1.7,1.4-2.9,2.4c-0.6,0-1.7,0.1-3,0.2c-0.1,1.2-0.1,2.4-0.2,3.7
    c-5.2,0-10.5,0-15.8,0c0-3.2,0-6.3,0-9.6C875.6,179.2,883,179.2,891,179.2z"></path>
            <path class="st26" d="M738.8,156.5c0,6.4,0,12.3,0,18.3c-4.1,0-8.3,0-12.7,0c0-3.2,0-6.3,0-9.7C730.6,163.1,734.2,159.6,738.8,156.5
    z"></path>
            <path class="st25" d="M1008,177.8c-3.6,0-6.7,0-9.9,0c0-4.9,0-9.7,0-14c1.7-1.7,3.2-3.2,5-5c1.9,1,3.1,3.1,5,4.4
    C1008,168.2,1008,173.1,1008,177.8z"></path>
            <path class="st25" d="M1178.8,169.9c-3.3,0-6.4,0-9.7,0c0-4.8,0-9.5,0-13.7c1.8-1.8,3.4-3.4,4.7-4.7c1.4,1.4,3,3,5,5
    C1178.8,160.3,1178.8,165,1178.8,169.9z"></path>
            <path class="st28" d="M884.8,174.9c-4,0-7.7,0-11.7,0c0-5.9,0-11.8,0-17.8c4.5,2,7.1,6.4,11.7,7.7
    C884.8,168.3,884.8,171.4,884.8,174.9z"></path>
            <path class="st29" d="M722.8,166.6c0,3.3,0,6,0,9.2c-2,0.5-4.3,0.1-6.6,0.2c-2.3,0.1-4.6,0-6.7,0c-2.4-1.6-1.4-3.7-1.6-5.8
    C712.7,168.2,718.1,169.4,722.8,166.6z"></path>
            <path class="st25" d="M1047.8,173.9c-2.3,0-4.4,0-6.7,0c0-3.9,0-7.7,0-11.5c1.5-0.1,2.3-1.5,3.4-2.4c1.1,0.7,1.4,2.4,3.3,1.9
    C1047.8,165.9,1047.8,169.7,1047.8,173.9z"></path>
            <path class="st25" d="M939.8,173.9c-2.3,0-4.4,0-6.7,0c0-3.9,0-7.7,0-11.5c1.2-0.5,2.6-1,3.2-2.4c1.2,0.8,1.6,2.5,3.5,2
    C939.8,165.9,939.8,169.7,939.8,173.9z"></path>
            <path class="st25" d="M755.1,149.8c0-2.8,0-5.5,0-8.4c0.7-0.8,1.6-1.7,2.4-2.5c1.8,0.2,2.3,1.6,3.4,2.4c0,2.7,0,5.5,0,8.5
    C759.1,149.8,757.3,149.8,755.1,149.8z"></path>
            <path class="st25" d="M856,149.9c-2.1,0-3.9,0-5.9,0c0-2.9,0-5.7,0-8.5c1-0.7,1.5-2,3.1-2.5c0.7,1,1.5,2.1,2.7,3.8
    C856,144.2,856,146.8,856,149.9z"></path>
            <path class="st25" d="M755.2,162.4c1.1-1.5,1.8-2.5,2.5-3.5c1.7,0.5,2.2,1.9,3.1,2.5c0,2.9,0,5.7,0,8.5c-1.9,0-3.7,0-5.7,0
    C755.2,167.1,755.2,164.3,755.2,162.4z"></path>
            <path class="st25" d="M853.4,159.1c0.5,0.8,1.3,1.8,2.6,3.6c0,1.4,0,4.1,0,7c-1.9,0-3.8,0-5.8,0c0-2.7,0-5.5,0-7.4
    C851.4,161.1,852.4,160.1,853.4,159.1z"></path>
            <path class="st25" d="M1192.2,158.3c0.8,1.5,1.5,2.7,2.1,3.9c0.2-0.2,0.5-0.5,0.7-0.7c0,2.7,0,5.3,0,8.3c-1.9,0-3.8,0-5.8,0
    c0-2.7,0-5.5,0-7.2C1190.3,160.9,1191,159.9,1192.2,158.3z"></path>
            <path class="st25" d="M850.2,122.4c1.1-1.5,1.8-2.5,2.5-3.5c1.7,0.5,2.2,1.9,3.1,2.5c0,2.9,0,5.7,0,8.5c-1.9,0-3.7,0-5.7,0
    C850.2,127.1,850.2,124.3,850.2,122.4z"></path>
            <path class="st25" d="M761,129.9c-2.1,0-3.9,0-5.9,0c0-2.9,0-5.7,0-8.5c1-0.7,1.5-2,3.4-2.6c0.5,1,1.2,2.2,1.8,3.4
    c0.2-0.2,0.4-0.4,0.7-0.6C761,124.2,761,126.8,761,129.9z"></path>
            <path class="st25" d="M1160,169.9c-2.1,0-3.9,0-5.9,0c0-2.9,0-5.7,0-8.5c1-0.7,1.5-2,3.1-2.5c0.7,1,1.5,2.1,2.7,3.8
    C1160,164.2,1160,166.8,1160,169.9z"></path>
            <path class="st25" d="M962.2,162.3c0.6-0.6,1.5-1.5,2.3-2.3c0.8,0.8,1.6,1.7,2.3,2.5c0,2.8,0,5.5,0,8.4c-1.6,0-3,0-4.7,0
    C962.2,168.1,962.2,165.3,962.2,162.3z"></path>
            <path class="st25" d="M1064.5,160c0.8,0.8,1.6,1.6,2.5,2.5c0,2.6,0,5.3,0,8.1c-1.5,0.9-3.1,0.1-4.8,0.5c0-3.1,0-5.9,0-8.8
    C1062.9,161.6,1063.7,160.8,1064.5,160z"></path>
            <path class="st30" d="M602,151c1.5,4.7,4.6,8.8,5.1,14.7c-2.4-2.4-4.1-4.1-6.1-6.2c0-1.9,0-4.2,0-6.5
    C600.3,151.8,600.2,150.9,602,151z"></path>
            <path class="st31" d="M790.8,177.2c-5,0.2-9.4,3.3-14.4,2.8c-1-0.8-0.1-1.8-0.7-2.8C781.1,177.2,786,177.2,790.8,177.2z"></path>
            <path class="st32" d="M820,177.2c4.5,0,9.1,0,13.7,0c0.6,0.6,0.1,1.5,0.3,2.2c-2.5,1.7-4.8-0.5-7.3-0.4
    C824.4,179,822.4,177.5,820,177.2z"></path>
            <path class="st33" d="M890.8,175.9c-0.9,0-1.7,0-2.7,0c0-2.9,0-5.8,0-8.6c1.3-0.8,1.8,0.3,2.7,1
    C890.8,170.7,890.8,173.3,890.8,175.9z"></path>
            <path class="st34" d="M562,184c-0.4-1.1-1.4-1.9-1-3.3c0.1-0.4-0.4-0.8-1-0.7c-0.1-0.6,0.3-1.4-0.3-1.8c-0.8-0.6-0.7-1.4-0.7-2.2
    c0.5,0,1,0,1.4,0c0.9,2.8,4.2,4.1,4.6,7.4C564.3,184.7,563,183.7,562,184z"></path>
            <path class="st35" d="M1144,144c-0.1-1.2,2.2-1.3,0.8-2.9c1.5,0.1,1.7,1.8,3.2,0.5c0,1.6,0,2.5,0,3.5
    C1146.8,144.1,1145.4,143.9,1144,144z"></path>
            <path class="st36" d="M576,163c0-1.3,0.3-2.6-0.9-3.7c0.4-0.1,0.7-0.2,0.9-0.3c2,0.5,2.1,2,2,3.5C577.3,163.3,576.6,162.9,576,163z"></path>
            <path class="st37" d="M602,151c-1.5,0.1-0.8,1.2-1,2c-2.3-0.4,0.3-3.2-2.9-3.5C600.9,148.6,601.5,149.7,602,151z"></path>
        </svg>`;
  const backToTopLink =
    "<a href='#top' onClick='window.scrollTo(0, 0)' class='back_to_top'>Back to Top</a>";
  return (
    <Container
      className="skyline"
      dangerouslySetInnerHTML={{ __html: backToTopLink + svgTag }}
    />
  );
}

const Container = styled.div`
  position: relative;
  margin-top: 32px;
  padding-top: 32px;
  margin-bottom: -7px;
  height: auto;
  .st0 {
    fill: #0093a4;
  }

  .st1 {
    fill: #222222;
  }

  .st2 {
    fill: #b6e0e5;
  }

  .st3 {
    fill: #96d3d9;
  }

  .st4 {
    fill: #eff7f8;
  }

  .st5 {
    fill: #ededed;
  }

  .st6 {
    fill: #222323;
  }

  .st7 {
    fill: #232323;
  }

  .st8 {
    fill: #242424;
  }

  .st9 {
    fill: #252525;
  }

  .st10 {
    fill: #bedbde;
  }

  .st11 {
    fill: #40494a;
  }

  .st12 {
    fill: #3b4a4b;
  }

  .st13 {
    fill: #d3e3e5;
  }

  .st14 {
    fill: #86c6cd;
  }

  .st15 {
    fill: #9bced4;
  }

  .st16 {
    fill: #c2dde0;
  }

  .st17 {
    fill: #d0e2e4;
  }

  .st18 {
    fill: #9fcfd5;
  }

  .st19 {
    fill: #a3d1d6;
  }

  .st20 {
    fill: #b9dadd;
  }

  .st21 {
    fill: #d7e5e7;
  }

  .st22 {
    fill: #80c4cc;
  }

  .st23 {
    fill: #b8d9dd;
  }

  .st24 {
    fill: #0194a5;
  }

  .st25 {
    fill: #cbcbcb;
  }

  .st26 {
    fill: #0595a6;
  }

  .st27 {
    fill: #0094a4;
  }

  .st28 {
    fill: #0294a5;
  }

  .st29 {
    fill: #0997a7;
  }

  .st30 {
    fill: #169cab;
  }

  .st31 {
    fill: #0e99a9;
  }

  .st32 {
    fill: #0b97a8;
  }

  .st33 {
    fill: #25a1b0;
  }

  .st34 {
    fill: #129aaa;
  }

  .st35 {
    fill: #979797;
  }

  .st36 {
    fill: #22a0af;
  }

  .st37 {
    fill: #2aa3b1;
  }
`;
