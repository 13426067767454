import React, { useState } from 'react';
import styled from 'styled-components';
import { useMyBunac } from '../../hooks/useMyBunac';

const FORM_ID = 'ask_a_question';
const PIPELINE_ID = 29;
const PIPEDRIVES_FIELD_KEYS = {
  firstName: '708fa34401b62502ebd19eea42da067516ce1c95',
  lastName: '990162a948bd91785ecc26ce800992f1040c325b',
  email: 'email',
};

const SendMessageButton = styled.button`
  width: 100%;
  padding: 16px 0;
  background: #0094a5;
  border-width: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
`;

const ContactForm = () => {
  const [sent, setSent] = useState(false);
  const { request } = useMyBunac();
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    subject: null,
    message: null,
  });

  const handleChange = async event => {
    await setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const submit = async event => {
    event.preventDefault();

    const formRequest = buildFormRequest();

    try {
      await request(formRequest);
    } catch (error) {
      console.error(error.message);
    }

    await setSent(true);
  };

  const buildFormRequest = () => {
    const customFields = {
      [PIPEDRIVES_FIELD_KEYS.firstName]: formData.firstName,
      [PIPEDRIVES_FIELD_KEYS.lastName]: formData.lastName,
      [PIPEDRIVES_FIELD_KEYS.email]: formData.email,
      name: `${formData.firstName} ${formData.lastName}`,
    };

    const note = `
      <h1>Subject: ${formData.subject}</h1>
      <div>${formData.message}</div>
    `;

    if (dataLayer) {
      dataLayer.push({
        event: 'form',
        form_id: FORM_ID,
      });
    }

    return {
      path: '/form',
      method: 'POST',
      params: {
        id: FORM_ID,
        pipelineid: PIPELINE_ID,
        notes: JSON.stringify([{ model: 'deal', html: note }]),
        fields: JSON.stringify(customFields),
      },
    };
  };

  if (sent) {
    return (
      <div id="contactFormThanks">
        <p>&nbsp;</p>
        <p>
          Thanks for your question! You'll hear back from our team of Work
          Abroad Experts within the next couple of days. If you would like your
          question answered sooner please contact your local office{' '}
          <a href="/contact" target="_blanc">
            here
          </a>
          .
        </p>
      </div>
    );
  }

  return (
    <div className="contactUs">
      <h3>Ask us a question - we love to chat!</h3>
      <form onSubmit={submit}>
        <fieldset name="contactFieldset" id="contactFieldset">
          <div className="contactForm">
            <div className="half-width">
              <input
                type="text"
                onChange={handleChange}
                name="firstName"
                id="FirstName"
                required
              />
              <label htmlFor="FirstName">
                First Name: <span className="required">*</span>
              </label>
            </div>
            <div className="half-width">
              <input
                type="text"
                onChange={handleChange}
                name="lastName"
                id="LastName"
                required
              />
              <label htmlFor="LastName">
                Last Name: <span className="required">*</span>
              </label>
            </div>
            <div>
              <input
                type="email"
                onChange={handleChange}
                name="email"
                id="Email"
                className="emailInput"
                required
              />
              <label htmlFor="Email">
                Email: <span className="required">*</span>
              </label>
            </div>
            <div>
              <input
                type="text"
                onChange={handleChange}
                name="subject"
                id="Subject"
                required
              />
              <label htmlFor="Subject">
                Subject: <span className="required">*</span>
              </label>
            </div>
            <div>
              <textarea
                onChange={handleChange}
                name="message"
                rows="5"
                required
              />
              <label htmlFor="Body">
                Message: <span className="required">*</span>
              </label>
            </div>
            <div className="pull-right">
              <SendMessageButton type="submit" name="btnSendMessage">
                Send Message
              </SendMessageButton>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default ContactForm;
