import React from 'react';
import styled from 'styled-components';

const Content = ({ children, fullWidth = false, ...restProps }) => {
  return (
    <Container
      fullWidth={fullWidth}
      style={
        fullWidth ? { marginLeft: '0', margin: '0 auto', width: '100%' } : {}
      }
      className="container grid"
      {...restProps}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: calc(100% - 250px);
  padding: 0;
  margin-left: 250px;
  > :first-child {
    padding-top: 20px;
  }
  > div.html_content {
    width: 100%;
  }
  > :last-child {
    padding-bottom: 20px;
  }
  > div hr {
    height: 1px;
    color: #eee;
    background: #eee;
    font-size: 0;
    border: 0;
    margin: 16px 0;
  }
  @media screen and (min-width: 960px) {
    > * {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  @media screen and (max-width: 959px) {
    width: 100%;
    margin-left: 0px;
    padding-top: 16px;
    > :first-child {
      padding-top: 0px;
    }
    > * {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-left: 0px;
    padding-top: 16px;
    > * {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

export default Content;
